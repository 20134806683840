<template>
  <div class="excel-import">
    <input type="file" ref="fileInput" class="hidden" :accept="allowedFileTypes" @change="handleClick">
    <div
      @click="$refs.fileInput.click()"
      @drop="handleDrop"
      @dragover="handleDragover"
      @dragenter="handleDragover"
      class="px-8 py-16 cursor-pointer text-center border-2 border-dashed d-theme-border-grey-light d-theme-dark-bg text-xl">
      <feather-icon icon="UploadCloudIcon" svgClasses="h-16 w-16 stroke-current text-grey" class="block" />
      <span>{{ dropTitle }} </span>
      <span class="font-medium text-primary" @click.stop="$refs.fileInput.click()">Browse</span>
      <!-- <vs-button type="border" @click.stop="$refs.fileInput.click()">Browse</vs-button> -->
    </div>
  </div>
</template>

<script>

export default {
  props: {
    onSuccess: {
      type: Function,
      required: true
    },
    dropTitle: {
      type: String,
      required: true,
      default: 'Drop File or'
    },
    allowedFileTypes: {
      type: String,
      required: true
    }
  },
  data () {
    return {

      excelData: {
        header: null,
        results: null,
        meta: null
      }
    }
  },
  methods: {
    handleDragover (e) {
      e.stopPropagation()
      e.preventDefault()
      e.dataTransfer.dropEffect = 'copy'
    },
    handleDrop (e) {
      e.stopPropagation()
      e.preventDefault()
      const files = e.dataTransfer.files
      if (files.length !== 1) {
        this.$vs.notify({
          title: 'You Can\'t Do That',
          text: 'You may only upload one file at a time!',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
          position: 'top-right'
        })
        return
      }
      const rawFile = files[0] // only use files[0]
      this.processFile(rawFile)
    },
    handleClick (e) {
      const files = e.target.files
      const rawFile = files[0]
      if (!rawFile) return
      this.processFile(rawFile)
    },
    isFileType (file) {
      return new RegExp(this.allowedFileTypes.replaceAll('.', '').replaceAll(', ', '|')).test(file.name)
    },
    processFile (file) {
      if (!this.isFileType(file)) {
        this.$vs.notify({
          title: 'Invalid File Type',
          text: `Only supports upload ${this.allowedFileTypes} files`,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
          position: 'top-right'
        })
        return false
      }

      this.$refs['fileInput'].value = null // fix can't select the same excel
      this.onSuccess(file)
    }
  }
}
</script>
